<template>
    <div>
        <!-- v-loading="loading" -->
        <el-dialog
            width="556px"
            :visible="dialogVisible"
            :show-close="false"
            :lock-scroll="true"
            @close="cancelHandle"
            :title="title"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            custom-class="create-project-dialog"
            append-to-body
        >
            <el-form
                class="project-form"
                ref="projectForm"
                :model="projectForm"
                :rules="projectFormRule"
                label-width="80px"
            >
                <el-form-item label="客户名称" prop="customerId">
                    <customer-select
                        ref="customerSelect"
                        placeholder="输入客户关键词"
                        @customerSelected="handleCustomerSelected"
                    ></customer-select>
                </el-form-item>
                <el-form-item
                    v-if="aCustomerBusinessGroup.length > 0"
                    label="业务分组"
                    prop="customerBusinessGroupId"
                >
                    <el-select
                        v-model="projectForm.customerBusinessGroupId"
                        placeholder="请选择业务分组"
                    >
                        <el-option
                            v-for="(item, index) in aCustomerBusinessGroup"
                            :key="'customerBusinessItem_' + index"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="部门名称" prop="customerDeparmentId">
                    <customer-department-select
                        ref="departmentSelect"
                        :isDisabled="!projectForm.customerId"
                        :customerId="projectForm.customerId"
                        placeholder="输入部门名称"
                        :departmentId="projectForm.customerDeparmentId"
                        @update-department="updateDepartment"
                    ></customer-department-select>
                </el-form-item>
                <!-- <el-form-item
                        label="职位类别"
                        prop="jobSerieId">
                        <job-series-select
                            ref="jobSeriesSelect"
                            placeholder="输入职位类别"
                            @jobSeriesSelected="changeJobSeries"
                        ></job-series-select>
                    </el-form-item> -->
                <el-form-item label="所在地区" prop="locations" style="margin-bottom: 0;">
                    <el-input
                        class="location-input"
                        type="text"
                        placeholder="请选择，最多选择五项"
                        readOnly="true"
                        @click.native="showCitySelect"
                        :value="locationName"
                    ></el-input>
                    <div class="hot-city">
                        <span v-for="(city, key) in hotCityMap" :key="key" @click="selectHotCity(city.label, city.value)">{{city.label}}</span>
                    </div>
                </el-form-item>
                <div class="hr-infos">
                    <el-form-item>
                        <span slot="label" class="self-require-balel">HR 信息</span>
                        <el-form-item class="it-cel" prop="hrName">
                            <el-input
                                type="text"
                                v-model="projectForm.hrName"
                                placeholder="请输入 HR 姓名"
                            ></el-input>
                        </el-form-item>
                        <el-form-item class="it-cel" prop="hrTitle">
                            <el-input
                                type="text"
                                v-model="projectForm.hrTitle"
                                placeholder="请输入职位名称"
                                maxlength="30"
                            ></el-input>
                        </el-form-item>
                        <el-form-item class="it-cel" prop="hrPhone">
                            <el-input
                                type="text"
                                v-model="projectForm.hrPhone"
                                placeholder="请输入手机号或标准固话"
                            ></el-input>
                        </el-form-item>
                        <span class="or">或</span>
                        <el-form-item class="it-cel sel">
                            <el-input
                                type="text"
                                v-model="projectForm.hrWeChat"
                                maxlength="20"
                                placeholder="请输入社交号"
                                @change="handleHrWeChatChange"
                            >
                                <el-select v-model="projectForm.hrContactType" slot="prepend" placeholder="请选择">
                                    <el-option label="微信" value="1"></el-option>
                                    <el-option label="企微" value="2"></el-option>
                                    <el-option label="飞书" value="3"></el-option>
                                    <el-option label="钉钉" value="4"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                        <el-form-item class="it-cel" prop="hrEmail">
                            <el-input
                                type="text"
                                v-model="projectForm.hrEmail"
                                placeholder="请输入联系邮箱"
                            ></el-input>
                        </el-form-item>
                    </el-form-item>


                    <div class="tip-text">
                        <i class="el-icon-view secret"></i> <span class="secret">私密</span> HR 相关信息为私密信息，仅自己可见
                    </div>
                </div>
               

                <el-form-item label="项目名称" prop="suffixName">
                    <el-input
                        style="width: 180px"
                        class="project-name-input"
                        type="text"
                        v-model="projectForm.preName"
                        :title="projectForm.preName"
                        placeholder="输入项目名称"
                        disabled
                    ></el-input>
                    <span class="space">-</span>
                    <el-input
                        style="width: 170px"
                        placeholder="输入项目名称"
                        type="text"
                        v-model="projectForm.suffixName"
                        maxlength="45"
                        :disabled="
                            !projectForm.customerId ||
                            projectForm.locations.length == 0
                        "
                    ></el-input>
                </el-form-item>

                <el-form-item label="绑定职位" prop="bindJobs">
                    <el-select
                        multiple
                        filterable
                        popper-class="bind-job-select"
                        v-model="projectForm.JobIds"
                        placeholder="请选择"
                        :disabled="!projectForm.customerDeparmentId"
                    >
                        <el-option-group label="我发布的职位">
                            <el-option
                                class="noData"
                                disabled
                                value="-1"
                                v-if="myJobPositions.length == 0"
                            >
                                暂无数据
                            </el-option>
                            <el-option
                                v-for="item in myJobPositions"
                                :key="item.jobId"
                                :label="item.jobName"
                                :value="item.jobId"
                            >
                                <span class="option-l ell myJobName" :title="item.jobName">{{
                                    item.jobName
                                }}</span>
                                <!-- <span class="option-r"
                                    >发布者：{{ item.creator }}</span
                                > -->
                            </el-option>
                        </el-option-group>
                        <el-option-group label="其他职位">
                            <el-option
                                class="noData"
                                disabled
                                value="-1"
                                v-if="otherJobPositions.length == 0"
                            >
                                暂无数据
                            </el-option>
                            <el-option
                                v-for="item in otherJobPositions"
                                :key="item.jobId"
                                :label="item.jobName"
                                :value="item.jobId"
                            >
                                <span class="option-l ell">{{
                                    item.jobName
                                }}</span>
                                <span class="option-r"
                                    >发布者：{{ item.creator }}</span
                                >
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>

                <!-- <el-form-item label="招聘人数" prop="headcount">
                    <el-input
                        type="text"
                        v-model="projectForm.headcount"
                        placeholder="输入人数"
                    ></el-input>
                </el-form-item> -->
                <el-form-item label="HC数量" prop="headcount">
                    <el-input
                        type="text"
                        v-model="projectForm.headcount"
                        placeholder="输入人数"
                    ></el-input>
                </el-form-item>
                <el-form-item label="乐观程度" prop="optimisticDegree">
                    <el-select
                        v-model="projectForm.optimisticDegree"
                        placeholder="请选择乐观程度"
                    >
                        <el-option
                            v-for="item in optimisticDegreeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="节点重要性" prop="nodeImportance">
                    <el-select
                        v-model="projectForm.nodeImportance"
                        placeholder="请选择节点重要性"
                    >
                        <el-option
                            v-for="item in nodeImportanceList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="接入时间" prop="accessDate" v-if="isCFUser && projectForm.JobIds.length>0">
                    <el-date-picker
                        v-model="projectForm.accessDate"
                        type="date"
                        placeholder="请选择接入时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="项目描述" prop="description">
                    <el-input
                        type="textarea"
                        v-model="projectForm.description"
                        placeholder="请输入2000字内的项目描述"
                        maxlength="2000"
                        :rows="6"
                        :show-word-limit="true"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelHandle" :loading="commitLoadig"
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    @click="confirmHandle"
                    :loading="commitLoadig"
                    >确定</el-button
                >
            </span>
        </el-dialog>
        <select-city-dialog
            ref="selectCityDialog"
            @city-selected="handleCitySelect"
        ></select-city-dialog>
    </div>
</template>

<script>
import moment from "moment";
import hotCityMap from "@src/js/config/hotCity.json";
import SelectCityDialog from "#/component/common/dialog/select-city-dialog.vue";
import CustomerSelect from "#/component/common/customer-select.vue";
import CustomerDepartmentSelect from "#/component/common/customer-department-select.vue";
// import JobSeriesSelect from '#/component/common/job-series-select.vue';
import ProjectService from "#/js/service/projectService.js";
import projectDetailService from "#/js/service/projectDetailService.js";
import { validateTel, validEmails, } from "#/js/util/validate.js";
export default {
    components: {
        SelectCityDialog,
        CustomerSelect,
        CustomerDepartmentSelect,
        // JobSeriesSelect,
    },
    props: {
        title: {
            default: "添加项目",
            type: String,
        },
    },
    data() {
        return {
            optimisticDegreeList: [
                {
                    value: '1',
                    label: '乐观'
                },
                {
                    value: '2',
                    label: '不乐观'
                },
                {
                    value: '3',
                    label: '谨慎'
                },
            ],
            nodeImportanceList: [
                {
                    value: '1',
                    label: 'A类'
                },
                {
                    value: '2',
                    label: 'B类'
                },
            ],
            commitLoadig: false,
            projectId: "",
            projectName: "",
            dialogVisible: false,
            isEdit: false,
            loading: false,
            hotCityMap: hotCityMap,
            locationName: "",
            projectForm: {
                preName: "", // 部门+地区生成的前面部分名称
                suffixName: "", // 定义的项目名称
                customerBusinessGroupId: "", // 业务分组
                headcount: "", // 招聘人数
                customerId: "", // 客户
                customerDeparmentId: "", // 部门
                // jobSerieId: '',
                locations: [], // 地区
                hrName: '', // HR姓名
                hrPhone: '',
                hrWeChat: '',
                hrContactType: "1",
                hrTitle: '',
                hrEmail: '',

                JobIds: [], // 绑定职位
                description: "", // 项目描述

                optimisticDegree: "", // 乐观程度
                nodeImportance: "", // 节点重要性
                accessDate: "", // 接入时间
            },
            projectFormRule: {
                suffixName: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                    {
                        max: 100,
                        message: "项目名称长度应在100个字符以内",
                        trigger: "blur",
                    },
                ],
                customerBusinessGroupId: [
                    {
                        required: true,
                        message: "请选择业务分组",
                        trigger: "blur",
                    },
                ],
                headcount: [
                    {
                        required: true,
                        message: "请输入招聘人数",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: `请输入数字`,
                        trigger: "blur",
                    },
                    {
                        validator: (rules, value, callback) => {
                            if (value && value > 10000) {
                                callback(new Error("招聘人数不能超过10000"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                customerId: [
                    { required: true, message: "请选择客户", trigger: "blur" },
                ],
                customerDeparmentId: [
                    { required: true, message: "请选择部门", trigger: "blur" },
                ],
                // jobSerieId: [
                //     { required: true, message: "请选择职位类别", trigger: 'blur' }
                // ],
                locations: [
                    {
                        required: true,
                        message: "请选择所在地区",
                        trigger: "change",
                    },
                    {
                        validator: (rules, value, callback) => {
                            if (value && value.length > 5) {
                                callback(new Error("最多选择五项地区"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "change",
                    },
                ],
                hrName: [
                    {
                        required: true,
                        message: "请输入HR姓名",
                        trigger: "blur",
                    },
                ],
                hrPhone: [
                    // {
                    //     required: true,
                    //     message: "请输入HR手机",
                    //     trigger: "blur",
                    // },
                    { validator: this.validateMobile, trigger: ["blur", "change"] },
                ],
                hrTitle: [
                    {
                        required: true,
                        message: "请输入HR职位名称",
                        trigger: "blur",
                    },
                ],
                hrEmail: [
                    { validator: this.validateEmail, trigger: ["blur", "change"] },
                ],
                description: [
                    {
                        required: true,
                        message: "请输入项目描述",
                        trigger: "blur",
                    },
                    {
                        max: 2000,
                        message: "项目描述长度应在2000个字符以内",
                        trigger: "blur",
                    },
                ],
                optimisticDegree: [
                    { required: true, message: "请选择乐观程度", trigger: "change" },
                ],
                nodeImportance: [
                    { required: true, message: "请选择节点重要性", trigger: "change" },
                ],
                accessDate: [
                    { required: true, message: "请选择接入时间", trigger: "change" },
                ],
            },
            aCustomerBusinessGroup: [], // 业务分组
            allJobPositions: [],
            myJobPositions: [],
            otherJobPositions: [],
            value: "",
        };
    },
    created() {},
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
    },
    methods: {
        handleHrWeChatChange() {
            this.$refs.projectForm.validateField(
                "hrPhone",
                (valid) => {}
            );
        },
        validateEmail(rule, value, callback) {
            let emailReg = /^[A-Za-z0-9\u4e00-\u9fa5\._-]+@[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(?:\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
            if(value && value.length > 50) {
                callback(new Error('邮箱最长支持50个字符'));
            } else if(value && !emailReg.test(value)) {
                callback(new Error('请输入正确的电子邮箱'));
            } else {
                callback();
            }
        },
        validateMobile(rule, value, callback) {
            // let regexMobileExternal = /^(\+|00)\d{8,15}$/,
            let regexMobileExternal = /^(\+\d{2,4}-)?\d{2,4}-\d{7,8}$/,
                regexMobileInternal = /^(\+\d{2,4}-)?(1[3-9])\d{9}$/;
            let hrPhone = this.projectForm.hrPhone,
                hrWeChat = this.projectForm.hrWeChat;

            if((String(hrPhone).trim() == '' || hrPhone == null )&& (String(hrWeChat).trim() == '' || hrWeChat == null)) {
                callback(new Error('手机固话或社交号必须提供一个'));
            }

            if(!!hrPhone) {
                // let arr = hrPhone.match(regexMobileExternal) || hrPhone.match(regexMobileInternal);
                const regRes = regexMobileExternal.test(hrPhone) || regexMobileInternal.test(hrPhone);
                if(!regRes) {
                    callback(new Error('请输入正确号码，有区号用横杠-隔开'));
                } else {
                    callback();
                }
            }
            if(!!hrWeChat) {
                callback();
            }
        },
        show() {
            this.dialogVisible = true;
        },
        // 选择客户名称
        handleCustomerSelected(customer) {
            this.projectForm.customerId = customer.customerId;
            if (!customer.customerId) {
                this.projectForm.customerDeparmentId = "";
                // 清空业务分组数据
                this.projectForm.customerBusinessGroupId = "";
                this.aCustomerBusinessGroup = [];
            }
            this.getProjectBusinessGroup(customer.customerId);

            // 重选客户，清空之前选择的部门的值
            this.$refs.departmentSelect.reset();
            this.projectForm.customerDeparmentId = undefined;
            // 重选客户，清空select框上之前选择的我发布的职位或其他职位的值
            this.projectForm.JobIds = [];
            this.generateProjectName();

            this.$refs.projectForm.validateField("customerId", (valid) => {});
        },

        /**
         * 获取业务分组数据
         */
        getProjectBusinessGroup(customerId) {
            if (!customerId) return;
            projectDetailService
                .getProjectBusinessGroup(customerId)
                .then((res) => {
                    this.aCustomerBusinessGroup = res || [];
                });
        },

        // 更新部门选择
        updateDepartment(val) {
            this.$set(this.projectForm, "customerDeparmentId", val.id);
            // console.log(this.$refs);
            // console.log(this.$refs.projectForm.validateField);
            this.$refs.projectForm.validateField(
                "customerDeparmentId",
                (valid) => {}
            );
            this.generateProjectName();

            // 重选部门，清空select框上之前选择的我发布的职位或其他职位的值
            this.projectForm.JobIds = [];
            // 获取所有的职位列表
            this.getBindJobList(0);
            // // 获取我发布的职位列表
            // this.getBindJobList(1);
            // // 获取其他职位列表
            // this.getBindJobList(2);
        },

        // 生成项目前面部分名称
        generateProjectName() {
            // if(this.projectForm.customerDeparmentId && this.projectForm.jobSerieId && this.projectForm.locations.length > 0) {
            if (
                this.projectForm.customerDeparmentId &&
                this.projectForm.locations.length > 0
            ) {
                let departmentName = this.$refs.departmentSelect.selectText;
                departmentName = departmentName.slice(
                    departmentName.lastIndexOf("-") + 1,
                    departmentName.length
                ).replace(/\n/g,"").replace(/\r/g,"");
                // console.log(departmentName);
                // let jobSeriesName = this.$refs.jobSeriesSelect.jobSeriesName;
                // this.projectForm.preName = `${departmentName}-${jobSeriesName}-${this.locationName}`;

                // this.projectForm.preName = `${this.departmentName}-${this.locationName}`;
                // 命名时地区按首字母顺序排序
                let locationName = this.locationName
                    .split(",")
                    .sort((param1, param2) => {
                        return param1.localeCompare(param2, "zh");
                    })
                    //   .reverse()
                    .join(",");
                // console.log(locationName);
                this.projectForm.preName = `${departmentName}-${locationName}`;
            } else {
                this.projectForm.preName = "";
            }
        },
        // changeJobSeries(val) {
        //     this.$set(this.projectForm, 'jobSerieId', val.jobSeriesId);
        //     this.$refs.projectForm.validateField('jobSerieId', (valid) => {});

        //     this.generateProjectName();
        // },

        // 选择城市
        selectHotCity(name, code) {
            if (this.projectForm.locations.length == 5) {
                shortTips("最多选择五项地区！");
                return false;
            }
            if (this.projectForm.locations.indexOf(code) > -1) {
                return false;
            }
            this.projectForm.locations.push(code);
            switch (name) {
                case "北":
                    name = "北京市";
                    break;
                case "上":
                    name = "上海市";
                    break;
                case "广":
                    name = "广州市";
                    break;
                case "深":
                    name = "深圳市";
                    break;
                case "杭州":
                    name = "杭州市";
                    break;
                case "武汉":
                    name = "武汉市";
                    break;
                case "成都":
                    name = "成都市";
                    break;
                case "南京":
                    name = "南京市";
                    break;
                case "西安":
                    name = "西安市";
                    break;
                case "厦门":
                    name = "厦门市";
                    break;
                case "苏州":
                    name = "苏州市";
                    break;
                default:
                    break;
            }

            if (this.locationName) {
                this.locationName = `${this.locationName},${name}`;
            } else {
                this.locationName = name;
            }
            // this.$refs.selectCityDialog.selectCityOutside(code);
            // this.handleCitySelect();

            this.generateProjectName();
        },
        showCitySelect() {
            this.$refs.selectCityDialog.show();
            this.$nextTick(() => {
                if (this.$refs.selectCityDialog.$refs.provinceCity) {
                    this.$refs.selectCityDialog.$refs.provinceCity.clearSelected();
                    this.projectForm.locations.forEach((code) => {
                        this.$refs.selectCityDialog.$refs.provinceCity.selectCityOutside(
                            code
                        );
                    });
                }
            });
        },
        handleCitySelect() {
            let selected = this.$refs.selectCityDialog.selectedCity;
            // console.log(selected);
            let target = [];
            let targetNames = [];
            selected.forEach((item) => {
                target.push(item.id);
                targetNames.push(item.text);
            });
            this.projectForm.locations = target;
            this.locationName = targetNames.join(",");

            this.generateProjectName();
        },

        // 获取创建项目弹窗的绑定职位列表
        getBindJobList(filter) {
            let params = {
                CustomerId: this.projectForm.customerId,
                CustomerDepartmentId: this.projectForm.customerDeparmentId,
                Filter: filter,
                // Filter: 0,
                take: -1,
            };
            ProjectService.getBindJobList(params)
                .then((res) => {
                    this.allJobPositions = res.list;
                    // console.log(this.allJobPositions);
                    this.myJobPositions = [];
                    this.otherJobPositions = [];
                    this.allJobPositions.forEach((el) => {
                        // console.log(el);
                        if (el.isMyPublic) {
                            // 我发布的职位
                            this.myJobPositions.push(el);
                        } else {
                            this.otherJobPositions.push(el);
                        }
                    });
                    // console.log(this.myJobPositions);
                    // console.log(this.otherJobPositions);
                })
                .catch((err) => {
                    console.log(err);
                });
            // if (filter == 1) { // 我发布的职位
            //     ProjectService.getBindJobList(params)
            //         .then((res) => {
            //             // console.log(res);
            //             this.myJobPositions = res.list;
            //             // console.log(this.myjobPositions);
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            // } else if (filter == 2) { // 其他职位
            //     ProjectService.getBindJobList(params)
            //         .then((res) => {
            //             this.otherJobPositions = res.list;
            //             // console.log(this.otherJobPositions);
            //         })
            //         .catch((err) => {
            //             console.log(err);
            //         });
            // }
        },

        // 取消：关闭弹窗，清空数据
        cancelHandle() {
            this.dialogVisible = false;
            this.$refs.projectForm.resetFields();
            this.$refs.projectForm.$el.scrollTop = 0;
            this.$refs.customerSelect.reset();
            // this.$refs.jobSeriesSelect.reset();
            this.$refs.departmentSelect.reset();
            this.locationName = "";
            this.aCustomerBusinessGroup = [];
            this.projectForm.preName = "";
            this.projectForm.suffixName = "";
            this.projectForm.JobIds = [];
        },
        getHrWeChat(oldStr, type) {
            // {沟通渠道}|{对应号码}
            if(!oldStr) return '';
            var oldArr = oldStr.split("|");
            if(oldArr.length > 1) {
                return `${type}|${oldArr[1]}`
            } else {
                return `${type}|${oldStr}`
            }
        },
        // 点击确定按钮，提交创建项目
        confirmHandle() {
            this.$refs.projectForm.validate((isValid) => {
                if (isValid) {
                    // this.loading = true;
                    this.commitLoadig = true;
                    let params = {
                        Name:
                            this.projectForm.preName +
                            "$#$" +
                            this.projectForm.suffixName, // 项目名称
                        ...this.projectForm,
                        accessDate: moment(this.projectForm.accessDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                        hrWeChat: this.getHrWeChat(this.projectForm.hrWeChat, this.projectForm.hrContactType||'1'),
                    };
                    // console.log(this.projectForm.JobIds);
                    if(!this.isCFUser || !this.projectForm.JobIds.length>0) {
                        delete params.accessDate;
                    }
                    params.uodateType = 0;
                    console.log(params);
                    ProjectService.createOrUpdateProject(params)
                        .then((res) => {
                            // console.log(res);
                            shortTips("创建项目成功！");
                            this.projectId = res.id;
                            this.projectName = res.name;
                            this.$emit(
                                "aboutThisProject",
                                this.projectId,
                                this.projectName
                            );
                            // 保存一个值去判断是否创建项目成功
                            sessionStorage.setItem("isCreadProject", "true");
                            let baseData = {
                                headcount: params.headcount, // 招聘人数
                                optimisticDegree: params.optimisticDegree, // 乐观程度
                                nodeImportance: params.nodeImportance, // 节点重要性
                                accessDate: params.accessDate, // 接入时间
                            }
                            // console.log(baseData);
                            this.$emit("refresh-project-list", baseData, this.projectForm.JobIds);
                            this.cancelHandle();
                        })
                        .finally(() => {
                            //   this.loading = false;
                            this.commitLoadig = false;
                        })
                        .catch((error) => {
                            // console.log(error);
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scope>
.ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.create-project-dialog.el-dialog {
    .el-dialog__body,
    .el-dialog__footer {
        // padding-right: 0;
        padding-left: 49px;
        padding-right: 49px;
    }
    .project-form {
        width: 100%;
        // padding-right: 20px;
        max-height: 580px;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: 0; /* 设置比默认滚动条宽度更窄的宽度 */

        &::-webkit-scrollbar {
            display: none;
        }
        .el-date-editor.el-input, .el-date-editor.el-input__inner {
            width: 100%;
        }
        
        .el-form-item__label {
            margin-bottom: 0;
        }
        .el-select {
            width: 100%;
        }

        .hot-city {
            span {
                display: inline-block;
                padding: 0 4px;
                color: $primary;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .location-input .el-input__inner,
        .project-name-input .el-input__inner {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .el-form-item{
        padding-right: 10px;
    }
}
.space {
    margin: 0 3px;
}
.el-select__tags {
    .el-tag.el-tag--info {
        background-color: #ebf8f5;
        border-color: #ebf8f5;
        color: #40bc9d;

        .el-select__tags-text {
            display: inline-block;
            vertical-align: middle;
            max-width: 305px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .el-tag__close {
            background-color: #ebf8f5;
            border-color: #ebf8f5;
            color: #40bc9d;
            &:hover {
                background-color: #ebf8f5;
                border-color: #ebf8f5;
                color: #40bc9d;
            }
        }
    }
}
.hr-infos {
    border: 1px dashed #eee;
    padding: 0 0 10px 0;
    margin-bottom: 10px;
    background: #fcfcfc;
    >.el-form-item{
        margin-bottom: 2px;
        padding: 0 5px 0;
        >.el-form-item__label{
            width: 100%;
            text-align: left;
        }
        >.el-form-item__content{
            width: 100%;
            margin-left: 0!important;
        }
    }
    .el-form-item__content{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        &::before{
            display: none;
        }
        .it-cel{
            // width: 46%;
            // min-width: 46%;
            // max-width: 46%;
            width: 205px;
            margin-bottom: 18px;
        }
        .or{
            font-size: 14px;
            color: #666;
        }
        >.el-form-item{
            padding-right: 0;
        }
        .sel{
            .el-input-group__prepend{
                padding: 0;
                >.el-select{
                    margin: 0;
                    width: 90px;
                }
            }
        }
    }
    .self-require-balel{
        font-size: 14px;
        color: #666;
        &::before{
            content: '*';
            color: #F56C6C;
            margin-right: 4px;
        }
    }
    
    .tip-text{
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: #777;
        text-align: center;
        .secret{
            color: #F56C6C;
        }
    }
}
.bind-job-select {
    .noData {
        height: 40px;
        line-height: 40px;
        padding-left: 20px !important;
        margin-bottom: 0 !important;
        text-align: center;
        color: #ccc;
    }
    .el-select-group__wrap {
        max-width: 402px;
        padding-bottom: 2px !important;
        .el-select-group__title {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
            &::before {
                content: "";
                display: inline-block;
                width: 4px;
                height: 16px;
                background: #38bc9d;
                margin-right: 10px;
                vertical-align: -2px;
            }
        }
    }
    .el-select-group__wrap:not(:last-of-type)::after {
        display: none;
    }
    .el-select-group {
        padding: 0 20px;
        .el-select-dropdown__item {
            padding: 0;
            border-bottom: 1px solid #eee;
            .option-l {
                margin-right: 10px;
                display: inline-block;
                // width: 60%;
                width: 212px;
                color: #666;
                font-size: 14px;
                vertical-align: top;
            }
            .option-r {
                display: inline-block;
                // width: 39%;
                width: 140px;
                color: #999;
                font-size: 14px;
                text-align: right;
                vertical-align: top;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .option-l.myJobName{
                width: 100%;
            }
        }
        // .el-select-dropdown__item.hover,
        // .el-select-dropdown__item:hover {
        //     background-color: transparent !important;
        //     color: #38bc9d;
        // }
        .el-select-dropdown__item.selected {
            .option-l {
                color: #38bc9d;
            }
            &::after {
                display: none;
            }
        }
    }
}
</style>

