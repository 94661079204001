import {
    projectDetail as projectDetailUrl,
    project
} from '#/js/config/api.json';

export default {
    // getMyCustomerList() {
    //     return _request({
    //         method: "POST",
    //         url: customerListUrl.get_my_company_customers
    //     });
    // },
    getProjectDetailInfo(params) {
        return _request({
            url: projectDetailUrl.project_detail_info.replace(/%p/g, params.id),
            method: 'GET',
            baseURL: 'LbdOpenApi',
        });
    },

    // 获取未来月已交付的项目目标
    getPorjectFutureMonths(params) {
        return _request({
            url: projectDetailUrl.get_project_future_months.replace(/%p/g, params.projectId),
            method: 'GET',
            baseURL: 'LbdOpenApi',
        });
    },

    // 添加项目交付目标
    addOrUpdateProjectGoal(params) {
        return _request({
            url: projectDetailUrl.add_update_project_goal,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },

    // 更新交付信息
    updateDeliveryInfomation(params) {
        return _request({
            url: projectDetailUrl.update_delivery_infomation,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },

    // 删除项目
    deleteProject(params) {
        return _request({
            url: projectDetailUrl.delete_project.replace(/%p/g, params.projectId),
            method: "DELETE",
            baseURL: 'LbdOpenApi',
        });
    },

    // 项目添加/编辑
    createOrUpdateProject(params) {
        return _request({
            url: projectDetailUrl.create_or_update_project,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 项目添加/编辑
    getProjectGoal(params) {
        return _request({
            url: projectDetailUrl.get_project_goal.replace(/%p/g, params.projectId),
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 判断是否需要弹出创建交付目标的弹框
    hasProjectMyDeliveryGoal(params) {
        return _request({
            url: projectDetailUrl.has_project_goal.replace(/%p/g, params.projectId),
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 合并到另一项目
    // 获取可合并的项目列表
    getCanMergeProjects(params) {
        return _request({
            url: projectDetailUrl.get_project_merge.replace(/%p/g, params.projectId),
            method: "GET",
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    // 提交合并
    commitMergeProjects(params) {
        return _request({
            url: projectDetailUrl.commit_project_merge,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 获取项目最新动态
    getProjectLatestTrends(params) {
        return _request({
            url: projectDetailUrl.get_project_latest_trends,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 获取项目绑定的职位
    getProjectJobPage(params) {
        return _request({
            url: projectDetailUrl.get_project_job_page,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 获取项目绑定的职位
    BatchGetProjectNoBindingList(params) {
        return _request({
            url: projectDetailUrl.batch_get_project_no_binding_list,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },
    // 添加项目绑定的职位
    AddProjectJobBindings(params) {
        return _request({
            url: projectDetailUrl.add_project_job_bindings,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 删除项目职位绑定
    DeleteJobBinding(params) {
        return _request({
            url: projectDetailUrl.delete_job_bindings,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 获取我的交付目标（分页）
    getMyGoalPage(params) {
        return _request({
            url: projectDetailUrl.get_my_goal_page,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        });
    },

    // 获取未来月已交付的个人目标(个人)
    getPersonalFutureMonths(params) {
        return _request({
            url: projectDetailUrl.get_personal_future_months,
            method: 'GET',
            baseURL: 'LbdOpenApi',
        });
    },

    // 添加个人交付目标
    addOrUpdatePersonalGoal(params) {
        return _request({
            url: projectDetailUrl.add_update_personal_goal,
            method: "POST",
            baseURL: "LbdOpenApi",
            data: params
        })
    },
    // 获取业务分组接口
    getProjectBusinessGroup(customerId) {
        return _request({
            url: project.project_get_business_group.replace(/%p/g, customerId),
            method: 'GET',
            baseURL: 'LbdOpenApi',
        });
    },

};
