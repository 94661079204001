var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "556px",
            visible: _vm.dialogVisible,
            "show-close": false,
            "lock-scroll": true,
            title: _vm.title,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "custom-class": "create-project-dialog",
            "append-to-body": "",
          },
          on: { close: _vm.cancelHandle },
        },
        [
          _c(
            "el-form",
            {
              ref: "projectForm",
              staticClass: "project-form",
              attrs: {
                model: _vm.projectForm,
                rules: _vm.projectFormRule,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称", prop: "customerId" } },
                [
                  _c("customer-select", {
                    ref: "customerSelect",
                    attrs: { placeholder: "输入客户关键词" },
                    on: { customerSelected: _vm.handleCustomerSelected },
                  }),
                ],
                1
              ),
              _vm.aCustomerBusinessGroup.length > 0
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "业务分组",
                        prop: "customerBusinessGroupId",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择业务分组" },
                          model: {
                            value: _vm.projectForm.customerBusinessGroupId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.projectForm,
                                "customerBusinessGroupId",
                                $$v
                              )
                            },
                            expression: "projectForm.customerBusinessGroupId",
                          },
                        },
                        _vm._l(
                          _vm.aCustomerBusinessGroup,
                          function (item, index) {
                            return _c("el-option", {
                              key: "customerBusinessItem_" + index,
                              attrs: { label: item.name, value: item.id },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "部门名称", prop: "customerDeparmentId" } },
                [
                  _c("customer-department-select", {
                    ref: "departmentSelect",
                    attrs: {
                      isDisabled: !_vm.projectForm.customerId,
                      customerId: _vm.projectForm.customerId,
                      placeholder: "输入部门名称",
                      departmentId: _vm.projectForm.customerDeparmentId,
                    },
                    on: { "update-department": _vm.updateDepartment },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: "所在地区", prop: "locations" },
                },
                [
                  _c("el-input", {
                    staticClass: "location-input",
                    attrs: {
                      type: "text",
                      placeholder: "请选择，最多选择五项",
                      readOnly: "true",
                      value: _vm.locationName,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.showCitySelect.apply(null, arguments)
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "hot-city" },
                    _vm._l(_vm.hotCityMap, function (city, key) {
                      return _c(
                        "span",
                        {
                          key: key,
                          on: {
                            click: function ($event) {
                              return _vm.selectHotCity(city.label, city.value)
                            },
                          },
                        },
                        [_vm._v(_vm._s(city.label))]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "hr-infos" },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "self-require-balel",
                          attrs: { slot: "label" },
                          slot: "label",
                        },
                        [_vm._v("HR 信息")]
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入 HR 姓名",
                            },
                            model: {
                              value: _vm.projectForm.hrName,
                              callback: function ($$v) {
                                _vm.$set(_vm.projectForm, "hrName", $$v)
                              },
                              expression: "projectForm.hrName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrTitle" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入职位名称",
                              maxlength: "30",
                            },
                            model: {
                              value: _vm.projectForm.hrTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.projectForm, "hrTitle", $$v)
                              },
                              expression: "projectForm.hrTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrPhone" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入手机号或标准固话",
                            },
                            model: {
                              value: _vm.projectForm.hrPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.projectForm, "hrPhone", $$v)
                              },
                              expression: "projectForm.hrPhone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("span", { staticClass: "or" }, [_vm._v("或")]),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel sel" },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: "text",
                                maxlength: "20",
                                placeholder: "请输入社交号",
                              },
                              on: { change: _vm.handleHrWeChatChange },
                              model: {
                                value: _vm.projectForm.hrWeChat,
                                callback: function ($$v) {
                                  _vm.$set(_vm.projectForm, "hrWeChat", $$v)
                                },
                                expression: "projectForm.hrWeChat",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    slot: "prepend",
                                    placeholder: "请选择",
                                  },
                                  slot: "prepend",
                                  model: {
                                    value: _vm.projectForm.hrContactType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.projectForm,
                                        "hrContactType",
                                        $$v
                                      )
                                    },
                                    expression: "projectForm.hrContactType",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "微信", value: "1" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "企微", value: "2" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "飞书", value: "3" },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "钉钉", value: "4" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticClass: "it-cel", attrs: { prop: "hrEmail" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入联系邮箱",
                            },
                            model: {
                              value: _vm.projectForm.hrEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.projectForm, "hrEmail", $$v)
                              },
                              expression: "projectForm.hrEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tip-text" }, [
                    _c("i", { staticClass: "el-icon-view secret" }),
                    _c("span", { staticClass: "secret" }, [_vm._v("私密")]),
                    _vm._v(
                      " HR 相关信息为私密信息，仅自己可见\n                "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目名称", prop: "suffixName" } },
                [
                  _c("el-input", {
                    staticClass: "project-name-input",
                    staticStyle: { width: "180px" },
                    attrs: {
                      type: "text",
                      title: _vm.projectForm.preName,
                      placeholder: "输入项目名称",
                      disabled: "",
                    },
                    model: {
                      value: _vm.projectForm.preName,
                      callback: function ($$v) {
                        _vm.$set(_vm.projectForm, "preName", $$v)
                      },
                      expression: "projectForm.preName",
                    },
                  }),
                  _c("span", { staticClass: "space" }, [_vm._v("-")]),
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: {
                      placeholder: "输入项目名称",
                      type: "text",
                      maxlength: "45",
                      disabled:
                        !_vm.projectForm.customerId ||
                        _vm.projectForm.locations.length == 0,
                    },
                    model: {
                      value: _vm.projectForm.suffixName,
                      callback: function ($$v) {
                        _vm.$set(_vm.projectForm, "suffixName", $$v)
                      },
                      expression: "projectForm.suffixName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "绑定职位", prop: "bindJobs" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "popper-class": "bind-job-select",
                        placeholder: "请选择",
                        disabled: !_vm.projectForm.customerDeparmentId,
                      },
                      model: {
                        value: _vm.projectForm.JobIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.projectForm, "JobIds", $$v)
                        },
                        expression: "projectForm.JobIds",
                      },
                    },
                    [
                      _c(
                        "el-option-group",
                        { attrs: { label: "我发布的职位" } },
                        [
                          _vm.myJobPositions.length == 0
                            ? _c(
                                "el-option",
                                {
                                  staticClass: "noData",
                                  attrs: { disabled: "", value: "-1" },
                                },
                                [
                                  _vm._v(
                                    "\n                            暂无数据\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.myJobPositions, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.jobId,
                                attrs: {
                                  label: item.jobName,
                                  value: item.jobId,
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "option-l ell myJobName",
                                    attrs: { title: item.jobName },
                                  },
                                  [_vm._v(_vm._s(item.jobName))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-option-group",
                        { attrs: { label: "其他职位" } },
                        [
                          _vm.otherJobPositions.length == 0
                            ? _c(
                                "el-option",
                                {
                                  staticClass: "noData",
                                  attrs: { disabled: "", value: "-1" },
                                },
                                [
                                  _vm._v(
                                    "\n                            暂无数据\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._l(_vm.otherJobPositions, function (item) {
                            return _c(
                              "el-option",
                              {
                                key: item.jobId,
                                attrs: {
                                  label: item.jobName,
                                  value: item.jobId,
                                },
                              },
                              [
                                _c("span", { staticClass: "option-l ell" }, [
                                  _vm._v(_vm._s(item.jobName)),
                                ]),
                                _c("span", { staticClass: "option-r" }, [
                                  _vm._v("发布者：" + _vm._s(item.creator)),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "HC数量", prop: "headcount" } },
                [
                  _c("el-input", {
                    attrs: { type: "text", placeholder: "输入人数" },
                    model: {
                      value: _vm.projectForm.headcount,
                      callback: function ($$v) {
                        _vm.$set(_vm.projectForm, "headcount", $$v)
                      },
                      expression: "projectForm.headcount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "乐观程度", prop: "optimisticDegree" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择乐观程度" },
                      model: {
                        value: _vm.projectForm.optimisticDegree,
                        callback: function ($$v) {
                          _vm.$set(_vm.projectForm, "optimisticDegree", $$v)
                        },
                        expression: "projectForm.optimisticDegree",
                      },
                    },
                    _vm._l(_vm.optimisticDegreeList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "节点重要性", prop: "nodeImportance" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择节点重要性" },
                      model: {
                        value: _vm.projectForm.nodeImportance,
                        callback: function ($$v) {
                          _vm.$set(_vm.projectForm, "nodeImportance", $$v)
                        },
                        expression: "projectForm.nodeImportance",
                      },
                    },
                    _vm._l(_vm.nodeImportanceList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.isCFUser && _vm.projectForm.JobIds.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "接入时间", prop: "accessDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: { type: "date", placeholder: "请选择接入时间" },
                        model: {
                          value: _vm.projectForm.accessDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.projectForm, "accessDate", $$v)
                          },
                          expression: "projectForm.accessDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "项目描述", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入2000字内的项目描述",
                      maxlength: "2000",
                      rows: 6,
                      "show-word-limit": true,
                    },
                    model: {
                      value: _vm.projectForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.projectForm, "description", $$v)
                      },
                      expression: "projectForm.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.commitLoadig },
                  on: { click: _vm.cancelHandle },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.commitLoadig },
                  on: { click: _vm.confirmHandle },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("select-city-dialog", {
        ref: "selectCityDialog",
        on: { "city-selected": _vm.handleCitySelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }